import React from 'react'
import './map.css'
import { LocationOn } from '@mui/icons-material'

function Map() {
  return (
   <div className='mab_bod'>
        <h2>Location <LocationOn style={{color:'grey'}} /></h2>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px', width:'95%' }}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3111.9183759110274!2d-76.9017153248758!3d38.742635255822385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7a39ab90f9e57%3A0x9d66532f80e9c06a!2sLEWIS%20ALLEN%20DELIVERY%20SERVICES!5e0!3m2!1sen!2sus!4v1727042741105!5m2!1sen!2sus" 
        width="100%" 
        height="450" 
        style={{border:'none'}} 
        allowfullscreen="" 
        loading="lazy" 
        referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
   </div>
  )
}

export default Map