import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import "firebase/compat/auth";
import {getStorage} from 'firebase/storage';

// const firebaseConfig = {
//   apiKey: "AIzaSyB_-TYk179lSgIPfaUVS90gJB67z4S2wu4",
//   authDomain: "globe-cffb0.firebaseapp.com",
//   projectId: "globe-cffb0",
//   storageBucket: "globe-cffb0.appspot.com",
//   messagingSenderId: "1018819464439",
//   appId: "1:1018819464439:web:daef5cd282e3d6e6a997f2",
//   measurementId: "G-LZLED2Y6GK"
// };

const firebaseConfig = {
  apiKey: "AIzaSyDawTmxVKgHG0a7tPgq4PXTk9fdlsyupW8",
  authDomain: "allendelivery-b0abc.firebaseapp.com",
  projectId: "allendelivery-b0abc",
  storageBucket: "allendelivery-b0abc.appspot.com",
  messagingSenderId: "49913758493",
  appId: "1:49913758493:web:bccbdb41d84e3a8a1217f4",
  measurementId: "G-9ZVSW08P69"
};

const app = firebase.initializeApp(firebaseConfig)

export const db = app.firestore()

export  const auth = app.auth()

export const storage = getStorage(app)

export const Provider = new firebase.auth.GoogleAuthProvider()
